import React, { useState } from 'react';

const CourseContent = ({ course }) => {
  const [selectedModule, setSelectedModule] = useState(0);
  const [selectedLesson, setSelectedLesson] = useState(0);
  const [openModule, setOpenModule] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleModule = (index) => {
    if (openModule === index) {
      setOpenModule(null); // Collapse if already open
    } else {
      setOpenModule(index); // Open the selected module
      setSelectedLesson(0); // Reset to first lesson when opening a new module
    }
  };

  return (
    <div className="flex h-screen lg:flex-row">
      {/* Mobile toggle button */}
      <div className="lg:hidden p-4 bg-gray-100 border-b">
        <button
          className="bg-indigo-600 text-white px-4 py-2 rounded-lg"
          onClick={() => setSidebarOpen(true)}
        >
          Open Navigation
        </button>
      </div>

      {/* Sidebar for module navigation */}
      <div
        className={`fixed lg:relative top-0 left-0 w-64 bg-gray-100 p-4 border-r h-full z-50 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out lg:translate-x-0`}
      >
        <div className="lg:hidden flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Course Modules</h2>
          <button
            className="text-gray-600 text-2xl"
            onClick={() => setSidebarOpen(false)}
          >
            ×
          </button>
        </div>

        <ul className="space-y-4">
          {course.modules.map((module, moduleIndex) => (
            <li key={moduleIndex}>
              <div
                className="cursor-pointer p-3 rounded-lg hover:bg-indigo-100 transition flex items-center justify-between"
                onClick={() => toggleModule(moduleIndex)}
              >
                <div className="flex items-center">
                  <span className="inline-block w-6 h-6 bg-indigo-500 text-white font-semibold rounded-full text-center mr-3">
                    {moduleIndex + 1}
                  </span>
                  <span className="font-medium text-gray-800">{module.title}</span>
                </div>
                <span>{openModule === moduleIndex ? '▲' : '▼'}</span>
              </div>

              {/* Accordion for lessons */}
              {openModule === moduleIndex && (
                <ul className="pl-8 space-y-2 mt-2">
                  {module.lessons.map((lesson, lessonIndex) => (
                    <li
                      key={lessonIndex}
                      className={`cursor-pointer p-2 rounded-lg hover:bg-indigo-200 transition ${
                        selectedModule === moduleIndex && selectedLesson === lessonIndex
                          ? 'bg-indigo-300'
                          : ''
                      }`}
                      onClick={() => {
                        setSelectedModule(moduleIndex);
                        setSelectedLesson(lessonIndex);
                        setSidebarOpen(false); // Close sidebar on lesson select (for mobile)
                      }}
                    >
                      <span className="text-gray-700">{lesson.title}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Main content area */}
      <div className="flex-grow p-6 overflow-y-auto ml-0 lg:ml-64">
        <h1 className="text-3xl font-bold mb-4">{course.title}</h1>
        <p className="text-gray-700 mb-8">{course.description}</p>

        {/* Selected module and lesson content */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            {course.modules[selectedModule].lessons[selectedLesson].title}
          </h2>
          <p className="text-gray-600 mb-6">
            {course.modules[selectedModule].lessons[selectedLesson].description}
          </p>

          {/* Video */}
          <div className="relative pb-9/16 mb-6">
            <iframe
              className="rounded-lg"
              src={course.modules[selectedModule].lessons[selectedLesson].videoUrl}
              title={`Video for ${course.modules[selectedModule].lessons[selectedLesson].title}`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;