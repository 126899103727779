/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { UsersIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const people = [
  {
    "id": 47,
    "name": "Top Courses",
    "phone": "1-909-933-3184",
    "email": "top-courses@site.com",
    "role": "CTO",
    "url": "https://www.mail.com",
    "profileUrl": "top-courses",
    "imageUrl": "https://dummyimage.com/600x400/000/fff&text=top-courses"
  },
  {
      "id": 1,
      "name": "Category 1",
      "phone": "1-723-679-2322",
      "email": "category1@example.com",
      "role": "CTO",
      "url": "https://www.mail.com",
      "profileUrl": "category1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=category"
  },
  {
      "id": 2,
      "name": "Category 2",
      "phone": "1-371-719-7426",
      "email": "category2@web.com",
      "role": "Marketing Manager",
      "url": "https://www.domain.com",
      "profileUrl": "category2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=category"
  },
  {
      "id": 3,
      "name": "Category 3",
      "phone": "1-477-241-5921",
      "email": "category3@mail.com",
      "role": "CTO",
      "url": "https://www.mail.com",
      "profileUrl": "category3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=category"
  },
  {
      "id": 4,
      "name": "Category 4",
      "phone": "1-515-868-3362",
      "email": "category4@site.com",
      "role": "CTO",
      "url": "https://www.site.com",
      "profileUrl": "category4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=category"
  },
  {
      "id": 5,
      "name": "Category 5",
      "phone": "1-127-811-8502",
      "email": "category5@web.com",
      "role": "HR Director",
      "url": "https://www.domain.com",
      "profileUrl": "category5",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=category"
  },
  {
      "id": 6,
      "name": "Checkout 1",
      "phone": "1-106-160-2733",
      "email": "checkout1@mail.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "checkout1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=checkout"
  },
  {
      "id": 7,
      "name": "Checkout 2",
      "phone": "1-972-605-7757",
      "email": "checkout2@site.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.mail.com",
      "profileUrl": "checkout2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=checkout"
  },
  {
      "id": 8,
      "name": "Checkout 3",
      "phone": "1-799-894-8936",
      "email": "checkout3@site.com",
      "role": "Marketing Manager",
      "url": "https://www.web.com",
      "profileUrl": "checkout3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=checkout"
  },
  {
      "id": 9,
      "name": "Checkout 4",
      "phone": "1-504-643-3293",
      "email": "checkout4@example.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "checkout4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=checkout"
  },
  {
      "id": 10,
      "name": "Checkout 5",
      "phone": "1-441-474-8028",
      "email": "checkout5@site.com",
      "role": "HR Director",
      "url": "https://www.example.com",
      "profileUrl": "checkout5",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=checkout"
  },
  {
      "id": 11,
      "name": "Order 1",
      "phone": "1-562-248-4012",
      "email": "order1@web.com",
      "role": "Marketing Manager",
      "url": "https://www.example.com",
      "profileUrl": "order1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=order"
  },
  {
      "id": 12,
      "name": "Order 2",
      "phone": "1-621-611-6984",
      "email": "order2@mail.com",
      "role": "CTO",
      "url": "https://www.domain.com",
      "profileUrl": "order2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=order"
  },
  {
      "id": 13,
      "name": "Order 3",
      "phone": "1-946-796-7509",
      "email": "order3@web.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.site.com",
      "profileUrl": "order3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=order"
  },
  {
      "id": 14,
      "name": "History 1",
      "phone": "1-872-555-5960",
      "email": "history1@mail.com",
      "role": "CTO",
      "url": "https://www.site.com",
      "profileUrl": "history1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=history"
  },
  {
      "id": 15,
      "name": "History 2",
      "phone": "1-763-948-9580",
      "email": "history2@domain.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.domain.com",
      "profileUrl": "history2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=history"
  },
  {
      "id": 16,
      "name": "History 3",
      "phone": "1-783-300-6950",
      "email": "history3@example.com",
      "role": "CTO",
      "url": "https://www.example.com",
      "profileUrl": "history3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=history"
  },
  {
      "id": 17,
      "name": "History 4",
      "phone": "1-404-720-8607",
      "email": "history4@domain.com",
      "role": "CTO",
      "url": "https://www.example.com",
      "profileUrl": "history4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=history"
  },
  {
      "id": 18,
      "name": "History 5",
      "phone": "1-301-762-8950",
      "email": "history5@web.com",
      "role": "HR Director",
      "url": "https://www.mail.com",
      "profileUrl": "history5",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=history"
  },
  {
      "id": 19,
      "name": "Product 1",
      "phone": "1-266-479-8297",
      "email": "product1@mail.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.web.com",
      "profileUrl": "product1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=product"
  },
  {
      "id": 20,
      "name": "Product 2",
      "phone": "1-947-337-3812",
      "email": "product2@domain.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.web.com",
      "profileUrl": "product2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=product"
  },
  {
      "id": 21,
      "name": "Product 3",
      "phone": "1-325-988-5045",
      "email": "product3@web.com",
      "role": "CTO",
      "url": "https://www.domain.com",
      "profileUrl": "product3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=product"
  },
  {
      "id": 22,
      "name": "Product 4",
      "phone": "1-741-797-2336",
      "email": "product4@example.com",
      "role": "CTO",
      "url": "https://www.web.com",
      "profileUrl": "product4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=product"
  },
  {
      "id": 23,
      "name": "Product 5",
      "phone": "1-553-166-2958",
      "email": "product5@site.com",
      "role": "Sales Lead",
      "url": "https://www.site.com",
      "profileUrl": "product5",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=product"
  },
  {
      "id": 24,
      "name": "Cart 1",
      "phone": "1-338-371-9090",
      "email": "cart1@example.com",
      "role": "CTO",
      "url": "https://www.web.com",
      "profileUrl": "cart1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=cart"
  },
  {
      "id": 25,
      "name": "Cart 2",
      "phone": "1-608-293-2620",
      "email": "cart2@domain.com",
      "role": "Marketing Manager",
      "url": "https://www.domain.com",
      "profileUrl": "cart2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=cart"
  },
  {
      "id": 26,
      "name": "Cart 3",
      "phone": "1-270-730-5871",
      "email": "cart3@domain.com",
      "role": "Marketing Manager",
      "url": "https://www.web.com",
      "profileUrl": "cart3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=cart"
  },
  {
      "id": 27,
      "name": "Store 1",
      "phone": "1-817-855-3532",
      "email": "store1@site.com",
      "role": "HR Director",
      "url": "https://www.site.com",
      "profileUrl": "store1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=store"
  },
  {
      "id": 28,
      "name": "Store 2",
      "phone": "1-250-226-5388",
      "email": "store2@web.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "store2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=store"
  },
  {
      "id": 29,
      "name": "Store 3",
      "phone": "1-378-465-1398",
      "email": "store3@web.com",
      "role": "HR Director",
      "url": "https://www.example.com",
      "profileUrl": "store3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=store"
  },
  {
      "id": 30,
      "name": "Store 4",
      "phone": "1-591-553-9936",
      "email": "store4@example.com",
      "role": "CTO",
      "url": "https://www.domain.com",
      "profileUrl": "store4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=store"
  },
  {
      "id": 31,
      "name": "About 1",
      "phone": "1-150-154-3744",
      "email": "about1@example.com",
      "role": "Marketing Manager",
      "url": "https://www.web.com",
      "profileUrl": "about1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=about"
  },
  {
      "id": 32,
      "name": "About 2",
      "phone": "1-798-592-4115",
      "email": "about2@web.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "about2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=about"
  },
  {
      "id": 33,
      "name": "About 3",
      "phone": "1-670-400-9124",
      "email": "about3@web.com",
      "role": "CTO",
      "url": "https://www.web.com",
      "profileUrl": "about3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=about"
  },
  {
      "id": 34,
      "name": "Landing 1",
      "phone": "1-343-997-4264",
      "email": "landing1@example.com",
      "role": "Co-Founder / CEO",
      "url": "https://www.site.com",
      "profileUrl": "landing1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=landing"
  },
  {
      "id": 35,
      "name": "Landing 2",
      "phone": "1-770-885-7268",
      "email": "landing2@web.com",
      "role": "HR Director",
      "url": "https://www.domain.com",
      "profileUrl": "landing2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=landing"
  },
  {
      "id": 36,
      "name": "Landing 3",
      "phone": "1-514-410-8205",
      "email": "landing3@domain.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "landing3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=landing"
  },
  {
      "id": 37,
      "name": "Landing 4",
      "phone": "1-432-718-3318",
      "email": "landing4@site.com",
      "role": "HR Director",
      "url": "https://www.domain.com",
      "profileUrl": "landing4",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=landing"
  },
  {
      "id": 38,
      "name": "Pricing 1",
      "phone": "1-144-727-7158",
      "email": "pricing1@domain.com",
      "role": "CTO",
      "url": "https://www.web.com",
      "profileUrl": "pricing1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=pricing"
  },
  {
      "id": 39,
      "name": "Pricing 2",
      "phone": "1-683-809-9727",
      "email": "pricing2@web.com",
      "role": "HR Director",
      "url": "https://www.domain.com",
      "profileUrl": "pricing2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=pricing"
  },
  {
      "id": 40,
      "name": "Pricing 3",
      "phone": "1-463-851-2882",
      "email": "pricing3@mail.com",
      "role": "CTO",
      "url": "https://www.web.com",
      "profileUrl": "pricing3",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=pricing"
  },
  {
      "id": 41,
      "name": "Detail 1",
      "phone": "1-867-863-2630",
      "email": "detail1@example.com",
      "role": "Sales Lead",
      "url": "https://www.mail.com",
      "profileUrl": "detail1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=detail"
  },
  {
      "id": 42,
      "name": "Detail 2",
      "phone": "1-165-603-1171",
      "email": "detail2@site.com",
      "role": "Marketing Manager",
      "url": "https://www.domain.com",
      "profileUrl": "detail2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=detail"
  },
  {
      "id": 43,
      "name": "Home 1",
      "phone": "1-341-184-3804",
      "email": "home1@mail.com",
      "role": "CTO",
      "url": "https://www.example.com",
      "profileUrl": "home1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=home"
  },
  {
      "id": 44,
      "name": "Home 2",
      "phone": "1-261-414-3613",
      "email": "home2@site.com",
      "role": "CTO",
      "url": "https://www.domain.com",
      "profileUrl": "home2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=home"
  },
  {
      "id": 45,
      "name": "Settings 1",
      "phone": "1-635-617-7958",
      "email": "settings1@site.com",
      "role": "CTO",
      "url": "https://www.mail.com",
      "profileUrl": "settings1",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=settings"
  },
  {
      "id": 46,
      "name": "Settings 2",
      "phone": "1-909-933-3184",
      "email": "settings2@site.com",
      "role": "CTO",
      "url": "https://www.mail.com",
      "profileUrl": "settings2",
      "imageUrl": "https://dummyimage.com/600x400/000/fff&text=settings"
  }
]

const recent = people

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Command_with_preview() {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(true)

  const filteredPeople =
    query === ''
      ? []
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(person) => (window.location = person.profileUrl)}>
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === '' || filteredPeople.length > 0) && (
                      <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                        <div
                          className={classNames(
                            'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                            activeOption && 'sm:h-96'
                          )}
                        >
                          {query === '' && (
                            <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">Recent searches</h2>
                          )}
                          <div className="-mx-2 text-sm text-gray-700">
                            {(query === '' ? recent : filteredPeople).map((person) => (
                              <Combobox.Option
                                as="div"
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                  classNames(
                                    'flex cursor-default select-none items-center rounded-md p-2',
                                    active && 'bg-gray-100 text-gray-900'
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <img src={person.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full" />
                                    <span className="ml-3 flex-auto truncate">{person.name}</span>
                                    {active && (
                                      <ChevronRightIcon
                                        className="ml-3 h-5 w-5 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>

                        {activeOption && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                            <div className="flex-none p-6 text-center">
                              <img src={activeOption.imageUrl} alt="" className="mx-auto h-16 w-16 rounded-full" />
                              <h2 className="mt-3 font-semibold text-gray-900">{activeOption.name}</h2>
                              <p className="text-sm leading-6 text-gray-500">{activeOption.role}</p>
                            </div>
                            <div className="flex flex-auto flex-col justify-between p-6">
                              <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                <dt className="col-end-1 font-semibold text-gray-900">Phone</dt>
                                <dd>{activeOption.phone}</dd>
                                <dt className="col-end-1 font-semibold text-gray-900">URL</dt>
                                <dd className="truncate">
                                  <a href={activeOption.url} className="text-indigo-600 underline">
                                    {activeOption.url}
                                  </a>
                                </dd>
                                <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
                                <dd className="truncate">
                                  <a href={`mailto:${activeOption.email}`} className="text-indigo-600 underline">
                                    {activeOption.email}
                                  </a>
                                </dd>
                              </dl>
                              <button
                                type="button"
                                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Send message
                              </button>
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== '' && filteredPeople.length === 0 && (
                      <div className="px-6 py-14 text-center text-sm sm:px-14">
                        <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-gray-900">No people found</p>
                        <p className="mt-2 text-gray-500">
                          We couldn’t find anything with that term. Please try again.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
