import React from "react";
import { courseData } from "./data";

const courseDetails = () => {
  return (
    <div className="container mx-auto p-4">
      {/* Course Image with Overlay Header */}
      <div className="relative mb-6">
        <img src={courseData.imageUrl} alt={courseData.title} className="w-full h-64 object-cover rounded-lg" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center p-6 rounded-lg">
          <h1 className="text-4xl font-bold text-white mb-2">{courseData.title}</h1>
          <p className="text-gray-300 mb-2">By {courseData.author}</p>
          <div className="flex items-center mb-4">
            <span className="text-yellow-500 mr-2">
              {"★".repeat(Math.round(courseData.rating))}
              {"☆".repeat(5 - Math.round(courseData.rating))}
            </span>
            <span className="text-gray-300">({courseData.ratingCount} ratings)</span>
          </div>
          <p className="text-gray-400 capitalize">{courseData.category}</p>
        </div>
      </div>

      {/* Sidebar and Details */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Sidebar */}
        <div className="lg:w-1/4 order-2 lg:order-1">
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <h3 className="text-lg font-semibold mb-2">Course Details</h3>
            <p><strong>Level:</strong> {courseData.level}</p>
            <p><strong>Duration:</strong> {courseData.duration}</p>
            <p><strong>Lectures:</strong> {courseData.lectures}</p>
            <p><strong>Language:</strong> {courseData.language}</p>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <button className="w-full bg-blue-500 text-white py-2 rounded-md mb-2">Add to Cart</button>
            <button className="w-full bg-gray-500 text-white py-2 rounded-md">Add to Wishlist</button>
          </div>
        </div>

        {/* Main Content */}
        <div className="lg:w-3/4 order-1 lg:order-2">
          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Description</h2>
            <p className="text-gray-700">{courseData.description}</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Requirements</h2>
            <ul className="list-disc list-inside text-gray-700">
              {courseData.requirements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Target Audience</h2>
            <ul className="list-disc list-inside text-gray-700">
              {courseData.targetAudience.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Curriculum</h2>
            {courseData.curriculum.map((section, index) => (
              <details key={index} className="mb-4 bg-white p-4 rounded-lg shadow-md">
                <summary className="font-semibold text-lg cursor-pointer">
                  {section.title}
                </summary>
                <ul className="list-disc list-inside text-gray-700 mt-2">
                  {section.content.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </details>
            ))}
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Instructor</h2>
            <div className="flex items-center">
              <img src={courseData.instructor.imageUrl} alt={courseData.instructor.name} className="w-16 h-16 rounded-full mr-4" />
              <div>
                <h3 className="text-lg font-bold">{courseData.instructor.name}</h3>
                <p className="text-gray-600">{courseData.instructor.bio}</p>
              </div>
            </div>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Student Feedback</h2>
            {courseData.reviews.map((review, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-md mb-4">
                <div className="flex items-center mb-2">
                  <span className="text-yellow-500 mr-2">
                    {"★".repeat(Math.round(review.rating))}
                    {"☆".repeat(5 - Math.round(review.rating))}
                  </span>
                  <span className="text-gray-600 font-semibold">{review.reviewer}</span>
                </div>
                <p className="text-gray-700">{review.comment}</p>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default courseDetails;
