import React, { useState } from "react";
import { Link } from "react-router-dom";
import { courseCategories, coursesData } from "./data";

const CourseCatalogue = () => {
    const [selectedCategory, setSelectedCategory] = useState("all");
  
    const filterCourses = (category) => {
      setSelectedCategory(category);
    };
  
    const filteredCourses =
      selectedCategory === "all"
        ? coursesData
        : coursesData.filter((course) => course.category === selectedCategory);
  
    return (
      <div className="bg-gray-100 p-4">
        <div className="container mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-center">Course Catalogue</h1>
  
          {/* Tabs for categories */}
          <div className="mb-4 flex justify-center">
          {courseCategories.map((category) => (
            <button
              key={category.id}
              className={`px-4 py-2 mx-2 rounded-md ${
                selectedCategory === category.id
                  ? "bg-blue-600 text-white"
                  : "bg-blue-500 text-white"
              }`}
              onClick={() => filterCourses(category.id)}
            >
              {category.name}
            </button>
          ))}
          </div>
  
          {/* Course catalogue grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {filteredCourses.map((course) => (
              <div
                key={course.id}
                className="course-card bg-white p-4 rounded-lg shadow-md"
              >
                <img
                  src={course.imageUrl}
                  alt={course.title}
                  className="w-full h-32 object-cover rounded-md mb-4"
                />
                <Link to={`/course-details`}>
                <h2 className="text-xl font-semibold mb-2">{course.title}</h2>
                </Link>
                <p className="text-gray-600 mb-2">By {course.author}</p>
                <div className="flex items-center mb-2">
                  <span className="text-yellow-500 mr-2">
                    {"★".repeat(Math.round(course.rating))}
                    {"☆".repeat(5 - Math.round(course.rating))}
                  </span>
                  <span className="text-gray-600">({course.ratingCount} ratings)</span>
                </div>
                <p className="text-gray-500 capitalize">{course.category}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default CourseCatalogue;